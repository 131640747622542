import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import ScrollProgress from 'components/scroll-progress';
import { Box } from '@mui/material';
import { useScroll } from 'framer-motion';

const Page = forwardRef(({ children, title = '', meta, ...other }, ref) => {
  const { scrollYProgress } = useScroll();

  return (
    <>
      <Head>
        <title>{`${title} | Elite Steak House`}</title>
        {meta}
      </Head>

      <Box ref={ref} {...other}>
        <ScrollProgress scrollYProgress={scrollYProgress} />
        {children}
      </Box>
    </>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
